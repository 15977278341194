@use 'styles/globals/breakpoints';

.block {
    margin: 0 0 5rem 0;
    width: 100%;
}

.hero {
    width: 100% !important;
}

.heroMargin {
    margin: 0 0 1rem 1rem;
}

.heroTitle {
    text-align: left;
    font: normal normal normal 5.4rem Didot Display;
    letter-spacing: 6.48px;
    padding-bottom: 1rem;
}

.heroSubtitle {
    font-size: 2.2rem;
    padding-bottom: 1rem;
}

.hideBlock {
    height: 0px;
    opacity: 0;
    display: none;
    transition: .3s cubic-bezier(0, 1, 0.5, 1);
    margin: 0;

}

.showBlock {
    height: auto;
    opacity: 1;
    display: block;
    transition: .3s cubic-bezier(0, 1, 0.5, 1);
}

.learnMoreBtn {
    text-align: center;
    margin: 6rem 0 4rem 0;
    position: relative;
    border-top: 1px solid #ccc;

    a {
        position: absolute;
        top: -2.6rem;
        left: 45%;
        cursor: pointer;

        @media screen and (max-width: breakpoints.$screen-sm) {
            left: 35%;
        }
    }
}

.hideMoreBtn {
    text-align: center;
    margin: 6rem 0 4rem 0;
    position: relative;
    border-top: 1px solid #ccc;

    a {
        position: absolute;
        top: -2rem;
        left: 48%;
        border-radius: 50%;
        padding: 1rem 1.2rem;
        line-height: 20px;

    }

}

.hideMoreBtn a:hover {
    border-radius: 50% !important;
}

.logoRight {
    display: block;
    text-align: right;
}

.logoLeft {
    display: block;
    text-align: left;
}

.imageBackgroundLayer {
    position: relative;
}

.logoAlign {
    position: absolute;
    top: 30%;
    right: 14%;
}

.hasContentSection {
    width: 100%;
    height: 78rem;
    // background: linear-gradient(180deg, white 20%, #F0F0F0 20%, #F0F0F0 20%, #F0F0F0 20%, #F0F0F0 20%);
    position: relative
}

.hasBottomContentSection {
    position: absolute;
    width: 60%;
    bottom: 2rem;
    padding: 2.5rem 5rem 2.5rem 5rem;
    z-index: 99;
}

.hasBottomContentSection h2 {
    font-size: 2.4rem;
    font-weight: bold;
    color: #4C7B9A;
    margin-bottom: 2rem;
    text-transform: uppercase;
}

.hasBottomContentSection a {
    text-transform: none;
}


.hasContentSectionImage {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

@media only screen and (max-width: breakpoints.$screen-md) {
    .heroMargin {
        margin: 0 0 1rem 0rem;
    }

    .hasBottomContentSection {
        width: 100%;
        position: static;
    }

    .hasBottomContentSection h2 {
        text-align: center;
    }

    .hasContentSection {
        background: none;
        height: auto;
    }
}

.image{
    width: 100%;
    object-fit: cover;
    height: auto;
}
