
.block{
    margin: 2rem 0 2rem 2rem;

    @media print {
        font-size: 1rem;
    }
}

.item{
    font-size: inherit;
    margin-bottom: 1.2rem;
}

.basic{
    margin-bottom: 0;
    line-height: 1.8;
    font-size: 1.4rem;
}

