
.block{
    min-width: 100px;
}


.iconDelete{
    width: 20px;
    margin-right: 0.5rem !important;
    fill: var(--ocean);
}
