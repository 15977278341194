

.optionsBlock{
    padding: 1rem 2rem 0 2rem;
    margin: 2rem 0 4rem 0;
    background-color: var(--white);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-sm);
    overflow: hidden;
    width: 100%;
    animation: fadeIn .25s;
    min-height: 290px;
}

.planBlock{
    @extend .optionsBlock;
    min-height: auto;
    height: auto;
}

.block{
    display: flex;
    height: auto;
    flex-direction: column;
    margin-bottom: 2rem;
}

.name{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid var(--dove);
    padding-bottom: 1rem;
    margin-bottom: 2rem;
}

.text{
    margin-left: 2rem;
}

.options{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}

.row{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 2rem;
    
    &:last-of-type{
        margin: 0
    }
}

.option{
    width: 20%;
}
