@use '/styles/globals/placeholders' as *;

.block{
    border-top: 1px solid var(--dove);
    padding: 4rem 2rem 0 2rem;
    order: 3;
}

.pod{
    margin-bottom: 4rem;
    border-right: 1px solid var(--dove);
    padding: 0 2rem;
}

.greenText{
    color: var(--green);
    font-weight: 700;
}

.heading{
    @extend %h2;
    font-size: 2.4rem;
    margin-bottom: 4rem;
}

.iconSave{
    width: 20px;
    margin-right: 0.5rem !important;
    fill: var(--ocean);
}

.button{
    margin: 0 3rem 0 0;
}

.options{
    margin: 2rem 0 0 0;
    list-style: none;
    padding: 0;
    flex: 1 0 auto
}

.optionItem{
    font-size: 1rem;
    line-height: 1.8;
    @extend %truncate;
}

.actions{
    padding: 2rem 0 0 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.actionItem{
    display: flex;

    &:first-of-type{
        margin-bottom: 1rem;
    }

    *{
        width: 100%;
    }
}

.actionControl{
    font-size: 1.3rem;
    padding: .5rem 0;
    line-height: 1;
    text-align: left;
    // text-decoration: underline;
    background-color: transparent;
    color: var(--link_color);
}

.noItemBlock{
    display: flex;
    padding: 2rem 0 6rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.noItemImage{
    margin-bottom: 3rem;
    border-radius: 99rem;
    background-color: var(--mist);
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg{
        fill: var(--ocean);
    }
}

.noItemHeader{
    @extend %h2;
    font-size: 2.1rem;
    margin-bottom: .5rem;
}

.noItemText{
    margin: 0;
}

.removedPod{
    align-items: center;
    display: flex;
    flex-direction: column;
    align-content: center;

    & strong{
        margin-bottom: 2rem;
    }
}

