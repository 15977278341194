@import "styles/globals/placeholders";
@import "/components/applications/pip/styles/sharedPip";

.mainImage{
    @extend %mainImage;
}

.btnCntr{
    margin-left: 0 !important;
    border: none !important;

    & button{
        max-width: unset !important;
    }
}

.logos{
    justify-content: flex-start !important;
    margin-bottom: 3rem;
}

