@use '/styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


.block{
    overflow: visible;
    padding-bottom: 8rem !important;

    & [class*="SwatchGroup_block"]{
        margin: 0;
    }
}


// modal
.selectedHeading{
    display: flex;
    padding: 2rem;
    margin: 2rem 0 0 0;
    background-color: var(--dove);
    align-items: center;
    justify-content: center;
}

.selectedHeadingText{
    margin-left: 1rem;
}

.moreOptionsHeading{
    @extend %h5;
    @include font.dynamic-font(18, 22);
    margin: 2rem 0;
    line-height: 1;
}

.moreOptions{
    display: flex;
    min-height: 100px;
    max-height: 300px;
    @extend %scrolling-inner;
    margin: 0 0 3rem 0;
    overflow-x: hidden;
    padding-right: 3rem;
    flex-direction: column;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 0;
    }
}

.optionRow{
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    flex: 1 0 auto;
}

.optionRowLabel{
    line-height: 1.2;
    margin: 0 0 0 2rem;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: breakpoints.$screen-md){
        margin-left: 1rem;
    }
}

.optionImage{
    display: flex;
    flex: 0 0 auto;
    width: 50px;
    margin: 0;
}

.button{
    position: absolute;
    bottom: -2px;
    width: 100%;
    left: 0px;
    right: 0;
}


