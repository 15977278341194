
.block{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-content: center;
    margin: 0;
    background-color: var(--white);
    padding: 1.5rem 1rem;
    box-shadow: var(--box-shadow-sm);
    max-width: 125px;
}

.toggleControl{
    width: 25px;
    height: 25px;
    padding: 0.5rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid var(--charcoal);
    background-color: transparent;
}

.control{
    height: 100%;
    width: 30%;
    margin: 0 0.25rem;
    border-color: transparent;
    text-align: center;
    min-width: 30px;
    appearance: textfield;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

}


.popover{
    z-index: 9;    
    padding: 2rem;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-lg);

    &[data-popper-placement^='top'] > .arrow {
        bottom: -4px;
    }
    
    &[data-popper-placement^='bottom']{
        box-shadow: 0 -.5rem 1rem rgba(#000, .15);

        & > .arrow {
            top: -4px;
        }
    }

    &[data-popper-placement^='left'] > .arrow {
        right: -4px;
    }
    
    &[data-popper-placement^='right'] > .arrow {
        left: -4px;
    }
}

.arrow{
    position: absolute;
    width: 15px;
    height: 15px;
    background: inherit;
    visibility: hidden;

    &::before {
        position: absolute;
        width: 15px;
        height: 15px;
        background: inherit;
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }
}


.error{
    color: var(--terracotta);
    margin: 0;
    display: flex;
    align-items: center;
}


.stepIconError{
    fill: var(--terracotta);
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  	transform: translate3d(0, 0, 0);
    margin-right: 1rem;
}


@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	  }
	  
	  20%, 80% {
		transform: translate3d(2px, 0, 0);
	  }
	
	  30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	  }
	
	  40%, 60% {
		transform: translate3d(4px, 0, 0);
	  }
}

