@use 'styles/globals/breakpoints';



.block, .tags{
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start;
    list-style: none;
    position: relative;

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding-left: 2rem;
    }
}

.tags{
    margin-bottom: 4rem;
}


.leftBtn, .rightBtn {
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--white) !important;
    box-shadow: var(--box-shadow-sm);
    border-radius: 50%;
    padding: 0.25rem;
    width: 40px !important;
    height: 40px;
    border: 1px solid #999;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
}

.leftBtn{
    left: -20px !important;

    @media only screen and (max-width: breakpoints.$screen-md) {
        left: 10px !important;
    }
}

.rightBtn{
    right: -20px !important;

    @media only screen and (max-width: breakpoints.$screen-md) {
        right: 10px !important;
    }
}
