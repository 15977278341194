@use "styles/globals/placeholders" as *;
@use 'styles/globals/breakpoints';



.wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.block{
    display: flex;
    flex-direction: column;
}

.breadCrumb{
    justify-content: flex-start;
    @extend %truncate;
    max-width: 99%;

    & [class^="Breadcrumb_link"]{
        @extend %truncate;
        max-width: 115px;

        @media only screen and (min-width: breakpoints.$screen-xxl) {
            max-width: 175px;
        }
    }
}

.affirm-as-low-as{
    a {
        border-bottom: 1px solid var(--link_color);
    }
}

.mainContainer{
    display: grid;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: flex;
        padding-top: 2rem;
    }
}

.compareBlock{
    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: none;
    }
}

.fillSpacer{
    content: "";
    background-color: #f8fafb;
    // width: 100vw;
    // position: absolute;
    // top: 0;
    // right: -100%;
    z-index: 8;
    height: 100%;
}






.about{
    background-color: var(--mist);
    padding: 10rem 0;
    display: flex;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 2rem;
    }
}



.imageBlock{
   flex-direction: column;
   padding: 6rem 4rem 8rem 0;
   position:relative;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 0 2rem 9rem 2rem;
    }
}



.infoBlock{
    align-content: center;
    padding: 6rem 1rem 6rem 6rem;
    justify-content: flex-start;
    background-color: var(--ice25);
    position: relative;

    @media only screen and (max-width: breakpoints.$screen-lg ) and (orientation: landscape) {
        padding: 0 0 0 5rem;
        grid-row: 1 / span 10;
    }

    @media only screen and (max-width: breakpoints.$screen-lg ) and (orientation: portrait) {
        &::after{
            display: none;
        }
    }


    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 4rem 2rem 0 2rem;
        grid-column: 2 / 10 !important;

        &::after{
            display: none;
        }
    }
}

.infoBlockSet{
    max-width: 100%;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    
    &::after{
        display: none;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 0 2rem !important;
        margin-bottom: 4rem;
    }
}


.setItemActions{
    display: flex;
    align-items: center;
    background-color: var(--ice50);
    padding: 2rem;
}





.noATC{
    &::after{
        content: "";
        flex: auto;
    }
}


.leadTime{
    padding: 2rem 0 !important;
    border-radius: var(--border-radius);
}


.multiSize{
    @extend %optionsBlock;
    padding-bottom: 1rem;
}

