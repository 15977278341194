
.block{
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.wrapper{
    display: flex;
    flex-direction: column;
    margin: 9rem 1rem 2rem 1rem;
    padding: 0 2rem 30rem 2rem;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    background-color: var(--white);
    position: relative;
}

.heading{
    position: absolute;
    background-color: var(--white);
    width: 100%;
    padding: 1rem 0;
    box-shadow: var(--box-shadow-sm);
    top: 0;
    left: 0;
    z-index: 9;
}

.close{
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    padding: 1rem;
    background-color: transparent;
}

.divider{
    margin: 2rem 0
}

.recommBlock{
    padding: 2rem 0
}
