@use 'styles/globals/breakpoints';

.block{
    display: flex;
    flex-direction: column;
    padding: 4rem;
    margin-bottom: 6rem;
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}
.leadTime{
    display: block;
    font-size: 12px;
    margin-top: 3px;
    font-weight: bold;
}

.image{
    width: 100%;
    object-fit: contain;
}

.list{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.item{
    margin: 0 0 4rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:last-of-type{
        margin: 0
    }
}

.itemPrice{
    margin: 0;
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    width: auto;
    flex-direction: column;
    align-items: flex-end;
}

.itemOptions{
    columns: 2;
    display: block;
    margin-top: 1rem;
    line-height: 1.4;
}

.info{
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 4rem;
    width: 70%;
}

.productReview{
    display: block;
    border-bottom: none;
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: breakpoints.$screen-sm) {

    .info{
        line-height: 1.2;
        margin-bottom: 0;
        margin-left: 1rem;
        margin-right: 1rem;
        width: 90%;
    }

    .block{
        padding: 2rem;
        margin-bottom: 20px;
    }

    .orderDetailList{
        display: none !important;
    }

    .orderDetailListMobile, .hiddenDesktop{
        display: block !important;
    }

    .orderCtaCntr button{
        width: 110% !important;
        font-size: 12px !important;
        padding: 5px 10px;

        &.writeReviewBtn{
            margin-top: 1rem !important;
        }
    }

    .blockDivider{
        margin: 20px 0px !important;
    }
}

.orderDetailList{
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end !important;
}

.hiddenDesktop{
    display: none;
}

.orderDetailListMobile{
    display: none;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end !important;
    margin-bottom: 10px !important;
}

.orderDetailCta{
    display: flex;
    margin: 0 !important;
}

.body{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.orderCtaCntr{
    display: flex;
    flex-direction: column;
}

.trackingCta{
    max-height: auto;
    text-align: right;
}

