@use 'styles/globals/breakpoints';
@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';


.list{
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 1rem;
}

.listRow{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--mist);
    padding: 3rem;

    & li{
        width: 33.3%;
        font-size: 2.1rem;

        @media screen and (max-width: breakpoints.$screen-md){
            width: 100%;
        }
    }
}

.listItem{
    padding-bottom: 1.5rem;
    display: flex;
    align-content: center;
    align-items: center;
}


.listLink{
    padding: 1rem;
    text-decoration: underline;
    display: inline-flex;
    align-content: center;
}

