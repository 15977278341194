@use 'styles/globals/breakpoints';

.generalSection{
    display: flex;
}
.total {
    font-size: inherit;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    font-size: 1.3rem;
    background-color: var(--ice);
    padding: 1rem;
    vertical-align: text-bottom;
    text-align: center;
}

.avg {
    font-size: 3rem;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 2.88px;
    color: #2E303B;
    text-transform: uppercase;
    display: block;
    margin-bottom: 2rem;
}

.btn {
    background-color: var(--ice) !important;
    text-decoration: underline;
    color: #4c7b9a;
}

.chart {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    font-size: 1.3rem;
    position: relative;
    padding: 0 0 0 3rem;
    width: 66%;
}

.numberCount {
    display: inline-block;
    padding-left: 9rem;
    vertical-align: text-top;
    font-size: 1.2rem;
}
.reviewSection button.btn {
    display: none;
}

.ratingChartScore { 
    --percent: calc(var(--rating) / 100 * 100%) !important;
    height: 1.5rem !important;
    width: 9rem !important;

    &::before {
        background: linear-gradient(90deg,
                var(--star-orangebackground) 100%,
                var(--star-color) var(--percent)) !important;
        -webkit-background-clip: text !important;
        background-clip: text !important;
        right: 1rem !important;
        left: unset !important;
    }

    &::after {
        letter-spacing: 8px !important;
        background: linear-gradient(90deg,
                var(--star-bluebackground) var(--percent),
                var(--star-color) var(--percent)) !important;

        -webkit-text-fill-color: transparent !important;
        left: 8rem !important;
        top: 3px !important;
        z-index: 10 !important;
        border-radius: 5px !important;
        height: 1rem !important;
        text-shadow: none !important;
    }

}

.reviewSection {
    display: flex;
    flex-direction: row;
}

.reviewSection .box {
    vertical-align: top;
}

.reviewSection .chart {
    width: auto;
}

.reviewSection .ratingChartScore {
    &::after {
        width: 33rem !important;
    }
}

.reviewSection .numberCount {
    padding-left: 32rem;
}


.starCount{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 0 0.5rem 0;

    &:last-of-type {
        padding: 0;
    }

   &:nth-child(2) span.ratingChartScore::before {
        content: "★★★★";
    }
    
    &:nth-child(3) span.ratingChartScore::before {
        content: "★★★";
    }
    
    &:nth-child(4) span.ratingChartScore::before {
        content: "★★";
    }
    
    &:nth-child(5) span.ratingChartScore::before {
        content: "★";
    }
}
  @media only screen and (max-width: breakpoints.$screen-sm) {
    .reviewSection .box {
            display: none;
        }

    .reviewSection .ratingChartScore {
        &::after {
            width: 20rem !important;
        }
    }
    .reviewSection .numberCount {
        padding-left: 19rem;
    }
}

