@use 'styles/globals/breakpoints';




.block{
    display: flex;
    padding: 2rem;
    width: 100%;
    border: 1px solid #ccc;
    padding: 2rem;
    margin: 0 auto;
    min-height: 610px;
    flex-direction: column;
    overflow: hidden;
}

.cntr{
    display: flex;
    scroll-snap-align: center;
    flex-flow: row nowrap;
    flex: none;
}

.fieldCntr{
    display: flex;
    align-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex: none;
    overflow: auto;
    scroll-snap-type: x mandatory;
    justify-content: center;
}

.fieldSet{
    border: none;
    padding: 4rem 0 0 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.title{
    width: 100%;
    flex: 1 0 auto;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 2rem;
    font-weight: 800;
    line-height: 1.2;
}

.controlNote{
    line-height: 1.4;
    text-align: center;
    margin: 1rem 0 2.5rem 0;
}

.controls{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    margin: 0 auto;

    &__short{
        width: 70%;

        @media screen and (max-width: breakpoints.$screen-sm){
            width: 100%;
        }
    }
}

.controlGroupHeading{
    width: 100%;
    padding-bottom: 2rem;
}

.controlGroupCntr{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 4rem;

    &__1_2{
        width: 50%;

        @media screen and (max-width: breakpoints.$screen-sm){
            width: 100%;
        }
    }
}

.controlGroup{
    margin-bottom: 4rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    flex-direction: row;

    &__noFlex{
        display: block;
    }

    &__right{
        justify-content: flex-end;
        text-align: right;
    }

    &__1_2{
        width: 50%;;
        justify-content: flex-end;

        &:nth-of-type(even){
            justify-content: flex-start;
        }
    }

    &:last-of-type{
        margin: 0;
    }

    &__col{
        flex-direction: column;
    }

    &__radioGroup{
        flex-direction: row;
    }
}

.controlGroupWithFigure{
    display: flex;
    flex-direction: row;
    padding-bottom: 4rem;
    width: 100%;


    @media screen and (max-width: breakpoints.$screen-sm){
        flex-direction: column;
    }
}

.controlLabel{
    order: 2;
    padding: 2rem;
    line-height: 1;
    text-transform: uppercase;
    line-height: 1.2;
    cursor: pointer;

    &__radioGroup{
        padding: 4rem;
        margin-bottom: 1rem;
        display: flex;
        height: 100%;

        @media screen and (max-width: breakpoints.$screen-sm){
            width: 100%;
        }
    }
}

.controlFigure{
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    position: relative;
    padding: 10px 0;
    opacity: 0.35;

    &:hover{
        opacity: 0.5;
    }

    @media screen and (max-width: breakpoints.$screen-sm){
        margin-bottom: 15px;
    }

    &__active{
        opacity: 1;

        &:hover{
            opacity: 1;
        }
    }
}

.controlFigureText{
    margin-top: 5px;
    text-transform: capitalize;
    text-align: center;
}


.controlHeading{
    width: 100%;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    margin: 0 0 5px 0;
}


.controlImg{
    height: 100px;
    width: 100px;
    object-fit: contain;
    border-radius: 50%;
}

.controlInput{
    order: 1;

    &[type=number], &[type=text]{
        padding: 10px;
        width: 25%;
        min-width: 75px;
        box-shadow: 0 0 0 1px #ccc inset;
        color: #444;
        border-radius: var(--border-radius);
        transition: border-color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        border: none;

        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button{
            -webkit-appearance: none; 
            margin: 0;
        }
    }

    &[type=radio]{
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;    
    }
}



.calcReferenceCntr{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    position: relative;
    width: 50%;

    @media screen and (max-width: breakpoints.$screen-sm){
        width: 100%;
        margin-bottom: 2rem;
    }
}

.figureName{
    width: 100%;
    display: block;
    font-weight: 800;
}

.figureNote{
    width: 100%;
    display: block;
}

.calcReferenceImg{
    object-fit: contain;
    width: 100% !important;
    max-width: 400px !important;
    margin: 0 auto;
}


.calcReferenceCaption{
    text-align: center;
    width: 100%;
    margin-top: 4rem;

}


.total{
    margin: 0; 
    text-align: center;
    line-height: 1.2;
    width: 100%;
    font-size: 1.8rem;

    @media screen and (max-width: breakpoints.$screen-sm){
        font-size: 1.2rem;
    }
}

.back {
    padding: 1.7rem 3.58rem !important;
    margin: 0 4rem 0 4rem;
    line-height: 1 !important;
}

.result {
    margin: 0;
    color: #55D58B;
    font-size: 3.3rem;
    text-align: center;
    line-height: 1.2;
    width: max-content;
    font-weight: bold;
    padding: 26px 54px;
    margin: 2rem auto;
    border-radius: 13px;
    box-shadow: 0px 3px 20px #84848429;

    @media screen and (max-width: breakpoints.$screen-sm){
        font-size: 2.8rem;
    }
}