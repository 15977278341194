@use 'styles/globals/breakpoints';


.block{
    display: flex;
    width: auto;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        padding: 0 4vw;
    }
}

.list{
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin: 0 0 0 -1rem;
    padding: 0;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        justify-content: space-between;
        margin: 0;
    }
}

.item{
    margin: 0 1rem;
    line-height: 1;
    display: flex;

    &:first-of-type{
        margin-left: 0;
    }

    &:last-of-type{
        margin-right: 0;
    }
}


.itemPrevious{
    display: flex;

    @media only screen and (max-width: breakpoints.$screen-md ) {
       border: 1px solid var(--link_color);
       border-radius: var(--button-border-radius);
       width: auto;
       align-items: center;
       align-content: center;
       justify-content: center;
       background-color: var(--ice50);
    }

    & .link{
        opacity: 1 !important;
    }

    & span{
        display: none; 
        
        @media only screen and (max-width: breakpoints.$screen-md ) {
            display: block;
            font-size: 1.8rem;
        }
    }
}

.itemNext{
    @extend .itemPrevious;
}

.itemNumber{
    display: flex;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: none;
    }
}

.itemDisabled{
    opacity: .25;
}



.link{
    background-color: transparent;
    padding: .75rem 1.5rem;
    color: var(--ocean);
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-content: center;
    line-height: 1;
    border-radius: var(--button-border-radius);
    border: 1px solid transparent;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        padding: .75rem 1rem;
    }

    &:hover{
        background-color: var(--ice);
        border-color: var(--ocean);
        border-radius: 99rem;
        text-decoration: none;
    }

    &:disabled{
        opacity: .25;
    }
}

.disabled{
    opacity: .25;
}

.ellipsis{
    align-self: center;
}

.active{
    background-color: var(--mist) !important;
    color: #777;
    border-color: #ccc !important;
    border-radius: 99rem;
}

.icon{
    width: 24px;
    height: 24px;
    fill: var(--ocean);
}



