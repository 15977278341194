%mainImage{
    object-fit: contain;
    width: 100%;
    height: auto;
}

%atcBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    max-height: 60px;
    border-bottom: 1px solid var(--ice);
    padding-bottom: 2rem;
    position: relative;
}

%atcStack{
    max-height: none;
}

%purchaseButtons{
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    & button{
        margin-left: 0;
    }
}
