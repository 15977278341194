@use 'styles/globals/placeholders' as *;

.block{
    width: 100%;
    padding: 2rem 0;
}

.offering{
    display: flex;
    padding: 2rem 0 ;
    flex-direction: column;
    border-bottom: 2px solid var(--dove);
    margin-bottom: 3rem;
}

.attached{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 0;
    border-bottom: 2px solid var(--dove);
    margin-bottom: 2rem;
}

.cartAttached{
    padding-top: 0;
}

.attachedBlock{
    position: relative;
}

.attachedItem{
    margin-left: 1rem;
}

.attachedText{
    font-size: 1.2rem;
    margin: 0;
    text-transform: capitalize;
    white-space: nowrap;
}

.attachedIcon{
    float: left;
    margin-right: 2rem;
}

.divider{
    margin: 1rem 0 !important;
}


.loading{
    filter: blur(3px);
}

.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
