@use 'styles/globals/breakpoints';


$maxFlex: 1300px;

.heading{
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    padding-bottom: 0;
    margin-bottom: 0;
}

.divider{
    margin: 2rem 0 5rem 0 !important;
}

.iconDone{
    margin: -2px 1rem 0 0;
    fill: var(--green)
}


.priceWrap{
    display: flex;
    align-items: baseline;
}


.cartActionsCntr{
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    // align-items: center;
    flex: 1 0 auto;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        width: 100%;
    }
}

.chosen{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 8rem 4rem 0 0;

    &__mini{
        width: 100%;
        padding: 1rem 0 0 0;
        order: 0 !important;

        & .productImg{
            margin: 0 auto;
            width: 100px;
            height: auto;
            object-fit: contain;
        }

        .features, .chosenProduct{
            padding: 0;
        }

        .chosenCntr{
            margin: 0
        }
    }

    &__widget{
        width: 100%;
        padding: 0;
        order: 0 !important;
        
        .productImg{
            margin: 0 auto;
            width: 50%;
            height: auto;
            object-fit: contain;
        }

        .features{
            width: 100%;
            padding: 2rem 0 0 0;
        }

        .chosenCntr{
            margin: 0
        }

        .chosenProduct{
            flex-direction: column;
            padding: 0
        }

    }


    @media only screen and (max-width: breakpoints.$screen-lg ) {
        width: 100%;
        padding-right: 0;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        order: 2;
    }

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        flex-direction: column;
    }
}

.chosenCntr{
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
}

.chosenProduct{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 4rem;
    border-bottom: 2px solid var(--dove);

    &:last-of-type{
        border: none
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        flex-direction: column;
    }
}

.confirmationBlock{
    display: flex;
    margin: 4rem 0;

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        flex-direction: column;
    }

    @media only screen and (max-width: breakpoints.$screen-xs ) {
        margin-top: 2rem;
    }
}

.cartInfo{
    padding: 8rem 4rem;
    background-color: var(--mist);
    display: flex;
    flex-direction: column;
    width: 50%;

    &__widget{
        width: 100%;
        padding: 2rem 4rem;
        position: absolute;
        z-index: 9;
        box-shadow: var(--box-shadow-lg);
        background-color: var(--white);
        border-top: 1px solid #ddd;
        bottom: 0;
        left: 0;
        right: 0;

        .subTotal{
            justify-content: center;
            align-content: center;
        }

        .cartTotals{
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 2rem;
            text-align: center;
        }
        .cartActions{
            flex-direction: column;
        }

        .actionButtons{
            padding: 0;
        }
    }

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        width: 100%;
        margin-top: 4rem;
        padding: 2rem;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        order: 1;
        margin: 0;
        padding-top: 4rem;
    }
}

.productImg{
    width: 30%;
    height: auto;
    object-fit: contain;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
    }
}

.productName{
    margin-bottom: 2rem;
    line-height: 1.3;
    text-transform: capitalize;
}

.productBrand{
    display: flex;
    margin: 0;
}

.optionListHeading{
    margin: 3rem 0 1rem 0;
    text-transform: uppercase;
    font-size: 1.3rem;
    width: 100%;
    padding-bottom: .5rem;
    border-bottom: 1px solid #ddd;;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wrap{
    flex-wrap: wrap !important;
}

.optionList{
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding :0;
    flex-wrap: wrap;
}

.optionItem{
    display: flex;
    align-content: center;
    align-items: center;
    width: 50%;
    margin-bottom: 1rem;

    &:nth-of-type(2n){
        justify-content: flex-end;
    }
}

.optionItemName{
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
}

.features{
    padding-left: 4rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        padding: 2rem;
    }
}


.cartTotals{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    width: 40%;

    @media only screen and (max-width: $maxFlex ) {
        width: 100%;
        text-align: center;
    }
}

.subTotal{
    display: flex;
    // flex: 1 0 auto;
    // width: 100%;
    align-items: center;
    line-height: 1;

    @media only screen and (max-width: $maxFlex ) {
        justify-content: center;
    }
}

.inCartText{
    display: block;
    padding-right: 1.5rem;
}


.cartActions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    // flex: 1 0 auto;

    @media only screen and (max-width: $maxFlex ) {
        flex-direction: column;
    }
}


.actionButtons{
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 4rem;

    @media only screen and (max-width: $maxFlex ) {
        padding: 4rem 0 0 0;
    }

}

.continueShopping{
    text-align: center;
    margin: 1rem 0 0 0;
}

.continueShoppingBtn{
    color: var(--link_color);
    text-decoration: underline;
    background-color: transparent;
}

.checkoutBtn{
    display: flex;
}

.shippingBlock{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        flex-direction: column;
    }
}

.shipType{
    width: 100%;
    display: flex;
    padding-right: 2rem;
    padding-top: 2rem;


    &:nth-child(2){
        padding: 0;
    }
    
    &:last-of-type{
        padding: 0;
        // justify-content: flex-end;
        // align-items: flex-start;
    }

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        padding: 0 2rem;

        &:last-of-type{
            padding: 0 2rem;
        }
    }
}

.freeShipping{
    display: flex;
    align-items: center;
    padding-right: 2rem;

    @media only screen and (max-width: breakpoints.$screen-xs ) {
        padding: 0;
        width: 100%;
        margin-bottom: 2rem;
    }
}

