
.block {
	display: flex;
	align-items: center;
	margin: 0 0 1rem 0;
	width: 100%;
}

.rating {
	--percent: calc(var(--rating) / 5 * 100%);
	position: relative;
	display: inline-block;
	font-size: var(--star-size);
	font-family: Times;
	line-height: 1;
	height: 15px;
	width: 78px;
	margin-right: 1.5rem;

	&::before {
		content: "★★★★★";
		letter-spacing: 3px;
		background: linear-gradient(90deg,
				var(--star-orangebackground) var(--percent),
				var(--star-color) var(--percent));
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
	}

	&::after {
		content: "★★★★★";
		letter-spacing: 3px;
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;
		text-shadow: 0px 0px 1px var(--star-orangebackground);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
}

.large{
	font-size: 3rem;
	width: 160px;
	height: 30px;
}

.total {
	font-size: inherit;
	margin: 0;
	line-height: 1;
}

