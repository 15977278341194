@use 'styles/globals/breakpoints';


.block {
    display: flex;
    position: relative;
    z-index: 1;
    min-height: 500px;
    height: auto;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--dove);
    border-right: 1px solid var(--dove);

    &:nth-of-type(3n) {
        border-right: none;
    }

    @media only screen and (max-width: breakpoints.$screen-md) {
        padding: 2em;
        width: 50%;
        max-width: inherit;
        justify-content: center;
        border: none;
    }

    @media only screen and (max-width: breakpoints.$screen-sm) {
        width: 100%;
        min-height: unset;
        max-height: unset;
    }
}


.container{
    position: relative;
    height: 100%;
    width: 100%;
    inset: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    z-index: 9;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        padding: 0;
    }

    // &::after {
    //     content: "";
    //     box-shadow: var(--box-shadow-lg);
    //     opacity: 0;
    //     transition: opacity 0.3s ease-in-out;
    //     position: absolute;
    //     inset: 0;
    //     width: 100% !important;
    //     height: 100% !important;
    //     border-radius: var(--border-radius);
    //     z-index: 0;
    // }

    // &:hover::after {
    //     opacity: 1;
    //     z-index: 0;
    // }

    &:hover {
        .meta{
            display: flex;
        }

        .figure{
            display: none;
        }

        .secondaryFigure{
            display: flex !important;
        }
    }
}




.linkBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    color: inherit;

    &:hover {
        text-decoration: none;
        color: inherit;
    }
}



.isLast {
    &::after {
        display: none;
    }
}



// POD Images
.cntr {
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    width: 100%;
}

.img {
    object-fit: contain;
    width: 100%;
    height: auto;
    max-height: 400px;

    &[src=""]{
        visibility: hidden !important;
    }
}

.figure {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    object-fit: cover;
    z-index: -1;

    @media only screen and (max-width: breakpoints.$screen-md) {
        min-height: 150px;
        max-height: unset;
    }
}


.secondaryFigure {
    background-position: center center;
    display: none;
    opacity: 1;
    height: 400px;
    width: 100%;
    object-fit: cover;
    transition: opacity 350ms ease-in-out 0s;
    overflow: hidden;
}


.imgLink {
    display: flex;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 9;
    overflow: hidden;
}

.compareControl{
    margin-top: 1rem;

    & label{
        font-size: 1.6rem;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: none !important;
    }
}

// Body
.body {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex: 1 0 auto;
    position: relative;
    z-index: 1;
    padding: 2rem 0 0 0;
}

.title {
    text-align: left;
    color: var(--link_color);
    margin: 0;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.4;

    &:hover {
        text-decoration: underline;
    }
}

.titleBlock {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    margin-bottom: 1rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        min-height: auto;
    }
}

.brand {
    font-size: 1.1rem;
    margin: 0;
}


// pricing
.price {
    flex: 1 0 auto;
    text-align: left;
    font-size: 2.1rem !important;
}


.metaCntr{
    display: flex;
    align-items: center;
}

.perUnit{
    padding-left: .5rem;
}


.badgeCntr {
    position: absolute;
    z-index: 10;
    top: .5rem;
    width: 50px;
    height: 50px;
    right: 1rem;
    z-index: 11;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        top: -27px;
     }

    @media only screen and (max-width: breakpoints.$screen-md) {
        right: -17px;
    }
}

.wideBadge{
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 0;

    & svg{
        width: 70%;
    }
}


.saleMsg {
    color: var(--terracotta);
    font-size: 1.4rem;
    text-align: left;
    font-weight: 600;
    margin: 0 0 0 0;
    width: 100%;
    text-align: left;
    line-height: 1;
    padding: .75rem 0;
}


.colorOptionsList{
    list-style: none;
    margin: 0;
    padding: 2rem 0 0 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
}

.colorOptionItem{
    margin: 0 .25rem;
    display: flex;
    align-items: center;

    &:first-of-type{
        margin: 0
    }
}

.colorOption{
    border-radius: 99em;
}


.moreShapesText{
    line-height: 1;
    margin-bottom: 1rem;
    color: #999;
}

