
.block{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.top{
    margin: 0 0 4rem 0;
}

