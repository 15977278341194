@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    align-items: flex-start;
    padding: 0 0 2rem 0;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        align-items: center;
    }
}

.text{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}

.icon{
    flex: 0 0 60px;
}

.iconCntr{
    width: 60px;
    height: 50px;
}

.rangeTime{
    font-weight: 800;
    margin-bottom: 0;
    @include font.dynamic-font(16, 18);
    display: flex;
    align-items: center;
}

.message{
    margin-bottom: 0;
    line-height: 1.5;

    a{
        text-decoration: underline;
    }
}


