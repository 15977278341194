@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';
@use 'styles/globals/placeholders' as *;



.block {
    @extend %standard-button;
    @extend %button-animate;

    &[download]{
        text-decoration: none;
    }

    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;

        &:hover{
            transform: none !important;
            box-shadow: none !important;
            transition: none !important;
            filter: none !important;
        }
    }

    & > svg{
        height: 21px;
        margin: 0 1rem 0 0;
    }

    @media screen and (max-width: breakpoints.$screen-md){
        font-size: inherit;
    }

    &:hover{
        color: var(--white);
        text-decoration: none;
        border-radius: var(--border-radius);

        & [class*="Loader_checkmark"]::after{
            border-color: var(--charcoal);
        }

        & > svg{
            fill: #fff;
        }
    }

    &:active, &:focus{
        // box-shadow: inset var(--box-shadow;
        transform: translate(0px, 1px);
    }
}

.autoHeight{
    height: auto !important;
    max-height: none !important;
}

.noAnimate{
    &:hover, &:active, &:focus{
        transform: none !important;
        box-shadow: none !important;
    }
}


.round{
    border-radius: 50% !important;
}


.size{

    // icon size button
    &__icon{
        padding: .5rem .5rem;
        max-width: 35px;
        max-height: 35px;

        & > svg{
            margin: 0;
        }
    }

    // full width button
    &__full{
        align-self: stretch;
        flex: 1 0 auto;
        width: auto;
    }


    // Specific Sizes
    &__xs{
        padding: 1rem calc(1vw + .25rem);
        @include font.dynamic-font(12, 13);
    }


    &__sm{
        padding: 1.2rem calc(1vw + .5rem);
        @include font.dynamic-font(14, 16);
    }

    &__md{
        padding: 1.5rem 1.5rem;
        @include font.dynamic-font(16, 18);
    }

    &__lg{
        padding: 1.6em calc(1vw + 1.7rem);
        @include font.dynamic-font(18, 26);
    }


    &__xl{
        padding: 1.7rem calc(1vw + 3rem);
        @include font.dynamic-font(21, 36);
    }
    

    &__xxl{
        padding: var(--standard-padding) calc(1vw + 4rem);
        font-weight: 800;
        border-width: 2px;
        @include font.dynamic-font(30, 40);
    }
}




.color{

    &__white{
        color: inherit;
        box-shadow: var(--box-shadow-sm);
        background-color: var(--white);

        &:hover{
            & svg{
                fill: var(--ocean);
            }
        }
    }

    // colors
    &__transparent{
        background-color: transparent;
        color: var(--ocean);
        padding: 1.7rem 0;
        line-height: 1.4;

        &:hover{
            background-color: inherit;
            border-color: transparent;
            color: var(--ocean);

            & [class*="Loader_checkmark"]::after{
                border-color: var(--ocean);
            }

            & > svg{
                fill: var(--ocean);
            }
        }
    }


    &__transparent-link{
        padding: 0 !important;
        font-size: inherit !important;
        text-transform: inherit;
        background-color: transparent;
        line-height: 1;
        border-bottom: 1px solid var(--ocean);

        &:hover{
            text-decoration: inherit;
            color: var(--indigo);
            background-color: transparent;
        }
    }


    &__green-outline{
        border-color: var(--green);

        background-color: transparent;
        color: var(--green);

        & > svg{
            fill: var(--green);
        }

        &:hover{
            background-color: var(--green);
            color: var(--green);

            & > svg{
                fill: var(--green);
            }

            & [class*="Loader_checkmark"]::after{
                border-color: var(--green);
            }
        }
    }


    &__dark-outline{
        border-color: var(--charcoal);
        background-color: transparent;
        color: var(--charcoal);

        & > svg{
            fill: var(--charcoal);
        }

        &:hover{
            background-color: #2e303b15; 
            color: var(--charcoal);

            & > svg{
                fill: var(--charcoal);
            }
        }
    }


    &__dove-outline{
        border-color: var(--dove);
        background-color: transparent;
        color: var(--dove);

        & > svg{
            fill: var(--dove);
        }

        &:hover{
            background-color: transparent;
            color: var(--dove);
        }
    }



    &__indigo-outline{
        border-color: var(--indigo);
        background-color: transparent;
        color: var(--indigo);

        & > svg{
            fill: var(--indigo);
        }

        &:hover{
            background-color: #353E5D90; 
            color: var(--indigo);

            & [class*="Loader_checkmark"]::after{
                border-color: var(--indigo);
            }

            & > svg{
                fill: var(--indigo);
            }
        }
    }

    &__ocean-outline{
        border-color: var(--ocean);
        background-color: transparent;
        color: var(--ocean);

        & > svg{
            fill: var(--ocean);
        }

        &:hover{
            background-color: #4c7b9a80; 
            color: var(--ocean);

            & [class*="Loader_checkmark"]::after{
                border-color: var(--ocean);
            }

            & > svg{
                fill: var(--ocean);
            }
        }
    }



    &__ice-outline{
        border-color: var(--ice);
        background-color: transparent;
        color: var(--ice);

        & > svg{
            fill: var(--ice);
        }

        &:hover{
            background-color: #4c7b9a50; 
            color: var(--ice);

            & [class*="Loader_checkmark"]::after{
                border-color: var(--ice);
            }

            & > svg{
                fill: var(--ice);
            }
        }
    }
}

