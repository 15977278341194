
.block{
    display: flex;
    flex-direction: column;
    padding: 4rem;
    margin-bottom: 6rem;
    border: 1px solid #ddd;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image{
    width: 100%;
    object-fit: contain;
}


.list{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.item{
    margin: 0 2rem 4rem 0;
    padding-bottom: 4rem;
    border-bottom: 1px solid var(--dove);
    display: flex;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:last-of-type{
        margin: 0;
        border-bottom: none;
        padding-bottom: 0;
    }
}

.itemPrice{
    margin: 0;
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    width: auto;
    flex-direction: column;
    align-items: flex-end;
}

.itemOptions{
    columns: 2;
    display: block;
    margin-top: 1rem;
    line-height: 1.4;
}

.label{
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin: 0;
}

.info{
    line-height: 1.2;
    margin-bottom: 0;
    margin-left: 4rem;
    width: 50%;
}

