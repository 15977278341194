
%checkbox-base{
    position: absolute;
    left: 0;
    opacity: 0.01;
}

%label{
    padding-left: 3rem;
    position: relative;
    width: 100%;
    cursor: pointer;
}

%checkbox-aspect{
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 20px;
    height: 20px;
    border: 1px solid var(--ocean);
    border-radius: var(--button-border-radius);
    background-color: var(--ocean);
    -webkit-transition: all .275s;
    transition: all .275s;
}

%checkmark{
    content: '';
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28' width='18' height='18' fill='%23fff'%3E%3Cpath d='M26.109 8.844c0 0.391-0.156 0.781-0.438 1.062l-13.438 13.438c-0.281 0.281-0.672 0.438-1.062 0.438s-0.781-0.156-1.062-0.438l-7.781-7.781c-0.281-0.281-0.438-0.672-0.438-1.062s0.156-0.781 0.438-1.062l2.125-2.125c0.281-0.281 0.672-0.438 1.062-0.438s0.781 0.156 1.062 0.438l4.594 4.609 10.25-10.266c0.281-0.281 0.672-0.438 1.062-0.438s0.781 0.156 1.062 0.438l2.125 2.125c0.281 0.281 0.438 0.672 0.438 1.062z'%3E%3C/path%3E%3C/svg%3E");    
    position: absolute;
    top: -1px;
    left: 1px;
    font-size: 1.3rem;
    color: #fff;
    line-height: 0;
    -webkit-transition: all .2s;
    transition: all .2s;
}

.upper{
    text-transform: uppercase;
}

.lower{
    text-transform: lowercase;
}

.capitalize{
    text-transform: capitalize;
}

.none{
    text-transform: none;
}


.block{
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    width: 100%;
    padding: 1rem 0;
    cursor: pointer;
    ;position: relative;

    &:hover{
        cursor: pointer;
    }
}

.control{
    &:not(:checked){
        @extend %checkbox-base;

        & + label{
            @extend %label;

            &::before{
                @extend %checkbox-aspect;
                background-color: var(--white);
            }

            &::after{
                @extend %checkmark;
                opacity: 0;
                transform: scale(0) rotate(45deg);
            }
        }
    }

    &:checked{
        @extend %checkbox-base;

        & + label{
            @extend %label;

            &::before{
                @extend %checkbox-aspect;
            }

            &::after{
                @extend %checkmark;
                opacity: 1;
                transform: scale(1) rotate(0);
            }
        }
    }

    &:disabled:not(:checked) + label, &:disabled:checked + label{
        cursor: not-allowed;
    }

    &:disabled:not(:checked) + label::before, &:disabled:checked + label::before{
        background-color: var(--mist);
    }

    &:not(:checked):focus + label::before, &:checked:focus + label::before{
        box-shadow: inset 0 1px 3px var(--ocean), 0 0 0 6px var(--ice)
    }

}


.disabled{
    cursor: not-allowed;
    opacity: 0.3;
}

.label{
    line-height: 1;
    margin-bottom: 0;
    display: flex;
    align-content: center;
}

