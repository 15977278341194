@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


.block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    line-height: 1.5;
    flex: 1 0 auto;
    text-decoration: none;
    padding: 0;
    background-color: transparent;
}

.selectedImage{
    width: 20px !important;
    height: 20px !important;
    min-height: auto !important;
    flex: 1 0 auto;
}

.item{
    display: flex;
    margin-bottom: 0.7rem;
}

.text{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: initial;   
}

.link{
    text-decoration: underline;
    @include font.dynamic-font(14, 18);
    color: var(--link_color);
}

.displayName{
    margin-right: 1rem;
    @include font.dynamic-font(14, 18);
    position: relative;

    @media screen and (max-width: breakpoints.$screen-md){
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        text-align: left;
        max-width: 130px;
        min-width: 115px;
    }
}

.label{
    display: flex;
    @include font.dynamic-font(14, 18);
    align-items: center;
}



.description{
    padding-left: 1rem;

    @media screen and (max-width: breakpoints.$screen-lg){
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        text-align: left;
        max-width: 175px;
    }
}

.optionImage{
    margin: 0 1rem;

    &_row{
        margin: 0 1rem 0 0;
    }
}

.hideOptionItemCntr{
    display: none;
}

