
.block{
    object-fit: contain;
    // height: 100%;
}

.image{
    object-fit: contain;
    width: 100%;
    height: auto;
}
