@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';



.brandLogo{
    margin-bottom: 2rem;
}

.brandLogo img{
    max-width: 360px !important;
    object-fit: contain;
    height: auto;
}


.content{
    @include font.dynamic-font(16, 18);
}

.resources{
    & > p{
        column-count: 2;
    }
}

.resourceLinkList{
    margin: 0;
    padding: 0;
    list-style: none;
}

.resourceItem{
    font-weight: 600;
    margin-bottom: 1rem;

    &:last-of-type{
        margin: 0;
    }
}

.resourceLink{
    // display: flex;
    align-items: center;
}

.resourceIcon{
    fill: var(--ocean);
    height: 25px;
    width: 25px;
    margin-right: 1rem;
}

.atGlance{
    grid-row: span 2 !important; 
}

.masonryCntr{
    display: grid;
    grid-template-columns: 1fr 50%;
    grid-gap: 4rem;

    // column-count: 2;
    // column-gap: 4rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        // column-count: 1;
        grid-template-columns: 1fr;
    }
}

.masonryColumn {
    padding-left: 4rem; /* gutter size */
    background-clip: padding-box;
}

.protection a {
    border-bottom: 1px solid var(--link_color);
    &:hover {
            text-decoration: none;
            color: inherit;
        }
}


.masonryBlock{
    break-inside: avoid;
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--border-radius);
    position: relative;
    -webkit-column-span: none;
    column-span: none;
    transition: box-shadow 0.3s ease-in-out;

    &:hover{
        box-shadow: var(--box-shadow);

        @media print{
            box-shadow: none;
        }
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        box-shadow: var(--box-shadow);
    }

    @media print{
        margin-bottom: 0;
    }
}

