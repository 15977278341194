
.block {
    display: flex;
    align-items: center;

    & button {
        background-color: #f8fafb;
        font-size: 1.4rem;
        text-decoration: underline;
    }
}

.rqStars .rating {
    height: 2.5rem;
}

.rqStars dl{
    width:auto;
}

.rqSection {
    position: relative;
}
.hasRatings {
    pointer-events: none;
}

.button{
    line-height: 1;
    height: 3em;
}

.ratingHover {
    display: none;
    position: absolute;
    background-color: #ffffff;
    text-decoration: none;
    border-radius: 2px;
    //margin-top: 1rem;
    padding: 2rem;
    width: 400px;
    left: -13rem;
    border: 1px solid var(--dove);
    z-index: 99999;
    box-shadow: var(--box-shadow);
}

.rqSection:hover .ratingHover {
    display: block;
}

.ratingHover:before {
    content: '';
    display: block;
    position: absolute;
    left: 14rem;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: var(--dove);
}

.ratingHover:after {
    content: '';
    display: block;
    position: absolute;
    left: 14.1rem;
    bottom: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: white;
}


.rating {
    --percent: calc(var(--rating) / 5 * 100%);
    position: relative;
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times;
    line-height: 1;
    height: 1.5rem;
    width: 9rem;
    margin-right: 0.5rem;

    &::before {
        content: "★★★★★";
        letter-spacing: 3px;
        background: linear-gradient(90deg,
                var(--star-orangebackground) var(--percent),
                var(--star-color) var(--percent));
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
    }


}

.topReview .rating::after {
    content: none;
}

.topReview .rating::before {
    content: "★★★★★";
    letter-spacing: 3px;
    background: linear-gradient(90deg,
            var(--star-orangebackground) var(--percent),
            var(--star-color) var(--percent));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
}

.showChart {
    display: block;
    width: 50%;
    border: 1px solid #4c7b9a;
    padding: 1rem;
}

.hideChart {
    display: none;
}

