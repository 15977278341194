@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';


.block{
    display: flex;
    flex-direction: column;
    margin: 5rem 0;
}

.toggleControl{
    background-color: var(--ice);
    margin: 0 0 3rem 0;
    padding: 2rem;
    line-height: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
}

.toggleIcon{
    width: 20px;
    height: 20px;
}


.list{
    list-style-type: none;
    margin: 0 0 4rem 0;
}

.listItem{
    margin-bottom: 1.5rem;
    background-color: var(--mist);
    padding: 2rem;

    &:last-of-type{
        margin: 0;
    }
}

.listLink{
    display: flex;
    flex-direction: column;
}


.listTitle{
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    color: initial;
}


.psedoLink{
    padding-top: 1rem;
    text-decoration: underline;
}


