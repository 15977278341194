@use "styles/globals/placeholders" as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';


.block{
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    margin-bottom: 2rem;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        width: 100%;
        margin-top: 4rem;
    }
}

.badgeCntr{
    position: absolute;
    top: 15px;
    width: 50px;
    height: 50px;
    right: -6rem;
    z-index: 10;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        right: -1rem;
        top:-3rem;
    }
}


.heading{
    font-family: var(--font-stack) !important;
    margin: 0 !important;
    text-transform: inherit;
    letter-spacing: 0.05rem;
    @include font.dynamic-font(20, 24);
    @extend %h2;
}

.heading2{
    @include font.dynamic-font(18, 20);
}

.headingLink{
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;

    &:hover {
        text-decoration: none;
    }
}

.cntr{
    display: flex;
    align-items: baseline;
    flex-direction: column;
    position: relative;
}


.byline{
    display: flex;
    font-size: 1.3rem;
    margin: 0 0 .5rem 0;
    font-weight: 500;
    font-style: normal;

    &::before{
        content: "See more by";
        margin-right: .5rem;
    }
}

.customByline{
    &::before{
        content: "";
        margin: 0;
    }
}

.bylineLink{
    text-decoration: underline;
}


.moreBy{
    text-transform: initial;
}



.detailCntr{
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    margin-top: 1rem;
    flex: 1 0 auto;
    width: 100%;
    justify-content: space-between;

    @media only screen and (max-width: breakpoints.$screen-md ) {
        margin: 0;
    }
}


.detail{
    display: flex;
    flex-direction: row;
    align-content: center;
}


.stars{
    margin-right: 5%;
    // flex: 1 0 auto;
    display: flex;
    font-size: 1.3rem;
}

.detailItem{
    margin-right: 5%;
    // flex: 1 0 auto;
    display: flex;
    font-size: 1.3rem;

    &:last-of-type{
        margin: 0;
    }

    @media only screen and (max-width: breakpoints.$screen-lg ) {
        display: none;
    }
}

.divider{
    @media only screen and (max-width: breakpoints.$screen-lg ) {
        margin-left: 0 !important;
    }

    @media only screen and (max-width: breakpoints.$screen-md ) {
        display: none;
    }
}



.sku{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    max-width: 130px;
    min-width: 60px;
}



.printBtn{
    display: flex;
    padding: 0 !important;
}

.printBtnTxt{
    font-size: 1.2rem !important;
}

.printIcon{
    fill: var(--ocean);
}

