


.block{
    word-break: keep-all;
    flex: 1 0 auto;
    background-color: var(--white);
}

.drawerButton{
    display: flex;
    padding: 1.5rem 2rem 1.5rem 2rem;
    font-size: 1.8rem;
    border-bottom: 1px solid var(--dove);
    background-color: var(--white);
    align-items: center;
    justify-content: space-between;
    line-height: 1 !important;
    align-content: center;
    color: inherit !important;
}

.active{
    border-color: var(--ocean) !important;
    border-width: 2px !important;
    color: var(--ocean) !important;
    font-weight: 800 !important;

    & svg{
        fill: var(--ocean) !important;
    }

    &:hover{
        background-color: var(--ice);

        & svg{
            fill: var(--ocean) !important;
        }
    }
}

.count{
    margin-left: .5rem;
}

.label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}

.toolTip{
    position: absolute;
    top: -2px;
    right: -30px
}

.activeFilter{
    font-weight: normal;
    font-size: 1.3rem;
    margin-top: .5rem;
    line-height: 1;
    color: #777;
    text-transform: capitalize;
}


.actions{
    margin: 0;
    background-color: var(--white);
    padding: 2rem;
    box-shadow: var(--box-shadow-sm);
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.actButton{
    margin-right: 2rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    flex: 0 50%;

    &:last-child{
        margin-right: 0;
    }
}

.sortIcon{
    transform: rotate(90deg);
    margin: 0 .5rem 0 0 !important;
    height: 20px !important;
}


.item{
    flex-shrink: 0;
    position: relative;
    scroll-snap-align: start;
    display: flex;
    margin-right: 1rem;
}
