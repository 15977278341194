

.block{
    list-style-type: none;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
}

.item{
    display: block;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid var(--dove);

    &:first-of-type{
        padding-top: 0;
    }

    &:last-of-type{
        border-bottom: none;
        padding-bottom: 0;
    }
}