

.block{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    align-content: center;

    & svg, img{
        margin-bottom: 1rem;
    }
}

