@use 'styles/globals/breakpoints';

.pod {
    display: flex;
    align-items: center;
    align-content: center;

    & img {
        width: 100%;
        object-fit: contain;
        padding: 1rem;
    }

    @media (max-width: breakpoints.$screen-md) {
        width: 50%;
    }

    @media only screen and (max-width: breakpoints.$screen-sm) {
        width: 100%;
    }
}


.inlineBanner {
    background-color: #f9f9f9;

    & img{
        width: 100%;
        object-fit: contain;
        position: static !important;
        height: auto !important;
        max-height: 500px;
    }

    & div {
        background-color: #fff;
        padding: 0;
        justify-content: flex-start;
        background-color: var(--mist);

        &::before {
            content: "";
        }
    }
}

/*nosto signup form*/

.nostoInlineEmail {
    position: relative;
}

.emailSignup {
    display: flex;
    position: absolute;
    top: 50%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;

}

.inputCntr input {
    width: 100%;
    height: 3.6rem;
    border: solid 2px #ddd;
    background-color: #fff;

}

.inputCntr input::placeholder {
    text-align: center;
    color: #cccccc;
}

button.joinListBtn {
    display: inline-flex;
    flex-direction: column;
    width: 50%;
    height: 3.6rem;
    margin-top: 3%;
    background: #2e3036;
    color: #fff;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1.5rem !important;
    border: solid 2px #000 !important;
}


.joinListBtn {
    & div {
        background-color: transparent !important;
    }
}


@media (max-width: breakpoints.$screen-sm) {
    .emailSignup {
        top: 38%;
    }

    button.joinListBtn {
        width: 75%;
    }

    .inputCntr input {
        width: 75%;
        margin: 0 2.5rem;
    }
}

