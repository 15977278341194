@use 'styles/globals/breakpoints';


.block{
    display: flex;
    align-content: center;
    align-items: center;
    @media only screen and (max-width: breakpoints.$screen-lg ) {
            display: inline;
        }
}

.inline{
    display: inline-flex;
    align-items: center;
}

.control{
    background: transparent;
    line-height: 1;
    margin: 0 .5rem;
    display: block;
    @media only screen and (max-width: breakpoints.$screen-lg ) {
        margin: 0;
        margin-left: .3rem;
            display: inline;
        }
}

.icon{
    width: 20px;
    height: 20px;
    fill: var(--link_color);
}

.closeBtn{
    padding: .25rem !important;
    position: absolute;
    top: 5px;
    right: 0;
    line-height: 1;
}

.text{
    margin: 0;
    font-size: 1.3rem;
    line-height: .9;
    font-weight: normal;
    text-transform: none !important;
    color: initial !important;
    letter-spacing: var(--letter-spacing) !important;
    // text-wrap: wrap;
}

.popover{
    z-index: 9;
    padding: 1rem;
    background: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-lg);
    width: 300px;
    position: relative;
    display: block;

    @media only screen and (max-width: breakpoints.$screen-sm ) {
        width: auto;
        max-width: 300px;
    }

    &[data-popper-placement^='top'] > .arrow {
        bottom: -4px;
    }

    &[data-popper-placement^='bottom']{
        box-shadow: 0 -.5rem 1rem rgba(#000, .15);

        & > .arrow {
            top: -4px;
        }
    }

    &[data-popper-placement^='left'] > .arrow {
        right: -4px;
    }

    &[data-popper-placement^='right'] > .arrow {
        left: -4px;
    }
}

