@use 'styles/globals/placeholders' as *;

.block{
    @extend %atcBtnBlock;

    & .PIP_price{
        font-weight: 400 !important;
    }
}

.ready{
    &:before{
        transform: translateX(600px)  skewX(-15deg);  
        opacity: 0.85;
        transition: 1.5s;
    }

    &:after{
        transform: translateX(600px) skewX(-15deg);  
        opacity: 1;
        transition: 1.5s;
    }
}

.readyBuyNow{
    @extend .ready;

    
}


